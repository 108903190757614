<template>
  <div class="text-h4 q-mb-md">設計工作營</div>
  <div class="q-pa-md row items-start q-gutter-none">
    <q-card class="my-card col-12 col-md-6">
      <q-card-section>
        <div class="text-h5 q-mb-xs">
          米蘭的時裝設計師兼插畫家Gloria Bellardi<br />
          時裝設計的研究方法 專題演講<br />
          <br />
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="gloriaBellardiImageList"
      />
      <q-card-section>
        <br />
        <br />
        <br />
        <br />
      </q-card-section>
    </q-card>
    <q-card class="my-card col-12 col-md-6">
      <q-card-section>
        <div class="text-h5 q-mb-xs">
          新生工作營<br />
          <br /><br />
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="xinshengImageList"
      />
      <q-card-section>
        <br />
        <br />
        <br />
        <br />
      </q-card-section>
    </q-card>
    <q-card class="my-card col-12 col-md-6">
      <q-card-section>
        <div class="text-h5 q-mb-xs">
          纖維藝術設計工作坊<br />
          <br /><br />
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="xianweiImageList"
      />
      <q-card-section>
        感謝林美玲老師的分享與指導，<br />
        創新且具有實驗性的表現方式，<br />
        跳脫傳統羊毛氈和染織工藝技法，結合異材質，<br />
        多元變化的形式，展現出創作者獨具一格的美學觀點。
      </q-card-section>
    </q-card>
    <q-card class="my-card col-12 col-md-6">
      <q-card-section>
        <div class="text-h5 q-mb-xs">
          服飾設計與經營學系與世界排名第五名的<br />
          時尚設計學院Istituto Marangoni<br />
          共同舉辦國際工作營
        </div>
      </q-card-section>
      <ExhibitionImage class="my-exhibition-image" :imageList="imageList" />
      <q-card-section>
        高雄-米蘭-倫敦 三城市同步連線（超強師資陣容：<br />
        米蘭校區時尚設計系主任+倫敦校區時尚造型系主任+倫敦校區時尚商業系主任）<br />
        實踐大學高雄校區服飾設計經營學系與國際知名設計學院Secoli共同舉辦工作營，<br />
        串聯全球教育資源，培育國際時裝產業專業人才
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import ExhibitionImage from "@/components/ExhibitionImage.vue";
import { useQuasar } from "quasar";
import { computed } from "vue";

export default {
  setup() {
    const $q = useQuasar();

    return {
      xinshengImageList: [
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3237_8244859_92937.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3242_2314068_93005.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3238_4415812_92949.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3239_6369740_92963.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3240_6100098_92975.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3241_7542716_92990.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3243_9297462_93019.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3244_867466_93032.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3245_7058704_93045.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3246_7975669_93059.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3247_7063367_93074.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3248_3425309_93089.jpg"
        }
      ],
      xianweiImageList: [
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3172_6872617_87147.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3180_2646726_87324.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3174_3055041_87179.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3175_17822_87194.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3176_7793739_87210.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3177_4097531_87224.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3179_2195614_87267.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3173_4854226_87165.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3215_3521928_90685.jpg"
        }
      ],
      imageList: [
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2954_2105899_38260.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2955_3623525_38275.jpg"
        }
      ],
      gloriaBellardiImageList: [
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3634_4633603_45766.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3634_4633603_45766.jpg"
        }
      ],
      layout: computed(() => {
        return $q.screen.lt.sm
          ? "dense"
          : $q.screen.lt.md
          ? "comfortable"
          : "loose";
      })
    };
  },
  components: {
    ExhibitionImage
  }
};
</script>
