<template>
  <div>
    <q-splitter
      class="full-height"
      v-model="splitterModel"
      style="height: 250px"
    >
      <template v-slot:before>
        <q-tabs v-model="tab" vertical class="text-teal">
          <q-tab
            name="移地教學"
            class="text-deep-orange-5 full-width material-icons"
            label="移地教學"
            >explore</q-tab
          >
          <q-tab
            name="設計工作營"
            class="text-deep-orange-5 full-width material-icons"
            label="設計工作營"
            >cabin</q-tab
          >
          <q-tab
            name="創作展演"
            class="text-deep-orange-5 full-width material-icons"
            label="創作展演"
            >preview</q-tab
          >
          <q-tab
            name="企業參訪"
            class="text-deep-orange-5 full-width material-icons"
            label="企業參訪"
            >tour</q-tab
          >
          <q-tab
            name="講座&說明會"
            class="text-deep-orange-5 full-width material-icons"
            label="講座&說明會"
            >forum</q-tab
          >
        </q-tabs>
      </template>

      <template v-slot:after>
        <q-tab-panels
          v-model="tab"
          animated
          vertical
          transition-prev="jump-up"
          transition-next="jump-up"
        >
          <q-tab-panel name="移地教學">
            <RelocationTimeline />
          </q-tab-panel>
          <q-tab-panel name="設計工作營"><WorkcampTimeline /> </q-tab-panel>
          <q-tab-panel name="創作展演">
            <CreationExhibition />
          </q-tab-panel>
          <q-tab-panel name="企業參訪"><CorporateVisit /> </q-tab-panel>
          <q-tab-panel name="講座&說明會"><DiscussionForum /> </q-tab-panel>
        </q-tab-panels>
      </template>
    </q-splitter>
  </div>
</template>

<script>
// @ is an alias to /src
import WorkcampTimeline from "@/components/WorkcampTimeline.vue";
import RelocationTimeline from "@/components/RelocationTimeline.vue";
import CreationExhibition from "@/components/CreationExhibition.vue";
import CorporateVisit from "@/components/CorporateVisit.vue";
import DiscussionForum from "@/components/DiscussionForum.vue";
import { ref } from "vue";

export default {
  setup() {
    return {
      tab: ref("移地教學"),
      splitterModel: ref(20)
    };
  },
  components: {
    WorkcampTimeline,
    RelocationTimeline,
    CreationExhibition,
    CorporateVisit,
    DiscussionForum
  }
};
</script>

<style>
.material-icons {
  font-size: 48px; /* Preferred icon size */
}
</style>
