<template>
  <div class="text-h4 q-mb-md">講座&說明會</div>
  <div class="q-pa-md row items-start q-gutter-none">
    <q-card class="my-card col-12 col-md-6">
      <q-card-section>
        <div class="text-h5 q-mb-xs" style="height: 24px">
          綠色時尚環保趨勢媒體訪問
        </div>
      </q-card-section>
      <ExhibitionImage class="my-exhibition-image" :imageList="lvseImageList" />
      <!-- <img src="https://cdn.quasar.dev/img/mountains.jpg" /> -->
    </q-card>
    <q-card class="my-card col-12 col-md-6">
      <q-card-section>
        <div class="text-h5 q-mb-xs" style="height: 24px">
          REHOW共同創辦人林怡君（Vicky）<br />
          布料改造手作 響應時尚零廢棄（Zero Waste）
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="rehowImageList"
      />
      <!-- <img src="https://cdn.quasar.dev/img/mountains.jpg" /> -->
    </q-card>
    <q-card class="my-card col-12 col-md-6">
      <q-card-section>
        <div class="text-h5 q-mb-xs" style="height: 24px">
          攜手合作亞洲第一大設計電商平台Pinkoi
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="pinkoiImageList"
      />
      <!-- <img src="https://cdn.quasar.dev/img/mountains.jpg" /> -->
    </q-card>
    <q-card class="my-card col-12 col-md-6">
      <q-card-section>
        <div class="text-h5 q-mb-xs" style="height: 24px">
          留學及獎學金說明會
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="scholarImageList"
      />
      <!-- <img src="https://cdn.quasar.dev/img/mountains.jpg" /> -->
    </q-card>
    <q-card class="my-card col-12 col-md-6">
      <q-card-section>
        <div class="text-h5 q-mb-xs" style="height: 24px">
          Harper_s BAZAAR Editor in Chief <br />時尚總編輯廖秀哖 專題演講
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="bazaarImageList"
      />
      <!-- <img src="https://cdn.quasar.dev/img/mountains.jpg" /> -->
    </q-card>
    <q-card class="my-card col-12 col-md-6">
      <q-card-section>
        <div class="text-h5 q-mb-xs" style="height: 24px">
          台北時裝周設計師董亭言專題演講
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="dongyantingImageList"
      />
      <!-- <img src="https://cdn.quasar.dev/img/mountains.jpg" /> -->
    </q-card>
    <q-card class="my-card col-12 col-md-6">
      <q-card-section>
        <div class="text-h5 q-mb-xs" style="height: 24px">
          國立成功大學創意產業研究所<br />
          所長劉世南博士演講
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="liushinanImageList"
      />
      <!-- <img src="https://cdn.quasar.dev/img/mountains.jpg" /> -->
    </q-card>
    <q-card class="my-card col-12 col-md-6">
      <q-card-section>
        <div class="text-h5 q-mb-xs" style="height: 24px">
          竇騰璜設計師時尚對話
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="doutenghuangImageList"
      />
      <!-- <img src="https://cdn.quasar.dev/img/mountains.jpg" /> -->
    </q-card>
  </div>
</template>

<script>
import ExhibitionImage from "@/components/ExhibitionImage.vue";

export default {
  setup() {
    return {
      lvseImageList: [
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3249_4564091_93474.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3250_5153261_93489.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3251_5172122_93501.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3252_5324581_93513.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3253_6326132_93528.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3254_3880535_93542.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3255_4212127_93555.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3256_1633344_93571.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3257_9221532_93584.jpg"
        }
      ],
      rehowImageList: [
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3214_7993148_90672.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3218_6249005_90728.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3216_6279030_90698.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3217_8622835_90713.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3219_8123582_90741.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3220_1808394_90753.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3221_6293334_91318.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3192_4367503_88986.jpg"
        }
      ],
      pinkoiImageList: [
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2897_9838711_49640.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2899_9432477_49767.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2898_568075_49743.jpg"
        }
      ],
      scholarImageList: [
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2904_650438_28343.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2902_8776569_28290.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2903_8837541_28319.jpg"
        }
      ],
      bazaarImageList: [
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2918_8565949_30696.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2919_79469_30716.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2920_2284118_30735.jpg"
        }
      ],
      dongyantingImageList: [
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2924_2672607_31114.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2922_4124701_31084.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2921_6678910_31068.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2923_8081581_31100.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2925_5064928_31130.jpg"
        }
      ],
      liushinanImageList: [
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2926_2602961_31901.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2927_1142946_31919.jpg"
        }
      ],
      doutenghuangImageList: [
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2928_5214533_32271.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2928_5214533_32271.jpg"
        }
      ]
    };
  },
  components: {
    ExhibitionImage
  }
};
</script>
