<template>
  <div class="text-h4 q-mb-md">創作展演</div>
  <div class="q-pa-md">
    <q-video :ratio="16 / 9" src="https://www.youtube.com/embed/BnF9vZ3sO0E" />
  </div>
  <div class="q-pa-md row items-start q-gutter-none">
    <q-card class="my-card col-12 col-md-6">
      <q-card-section>
        <div class="text-h5 q-mb-xs">
          新一代設計展
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="xindaiImageList"
      />
      <q-card-section>
        服飾設計與經營學系《新一代設計展》，作品打破思考框架，<br />
        呈現國際時尚的多元觀點，引領嶄新的跨領域美學形式，<br />
        沈浸式展覽空間和出色創意的設計作品，深獲肯定與好評，<br />
        在參觀者中激盪出熱烈的迴響！
      </q-card-section>
    </q-card>
    <q-card class="my-card col-12 col-md-6">
      <q-card-section>
        <div class="text-h5 q-mb-xs">
          甜上癮Fashion show
        </div>
      </q-card-section>
      <ExhibitionImage class="my-exhibition-image" :imageList="tianImageList" />
      <q-card-section>
        如同國際時裝週設計師每年兩次品牌 Collection 發表，<br />
        藉由Runway 伸展台來展現學生們對時尚的獨特品味與創意。<br />
        感謝內政部陳宗彥次長、宏遠興業蔡志成經理、林保源教務長<br />
        以及都發局長官們蒞臨指導。
      </q-card-section>
    </q-card>
    <q-card class="my-card col-12 col-md-6">
      <q-card-section>
        <div class="text-h5 q-mb-xs">
          高教深耕計畫成果展
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="shengengImageList"
      />
      <q-card-section>
        展示學系執行高等教育深耕計畫成果，<br />
        教學績效及學生學習成果。<br /><br />
      </q-card-section>
    </q-card>
    <q-card class="my-card col-12 col-md-6">
      <q-card-section>
        <div class="text-h5 q-mb-xs">
          實踐服經 X承億酒店 優選作品展
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="chengyiImageList"
      />
      <q-card-section>
        <br />
        <br />
        <br />
      </q-card-section>
    </q-card>
    <q-card class="my-card col-12 col-md-6">
      <q-card-section>
        <div class="text-h5 q-mb-xs">
          2022臺北時裝週AW22
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="zhinengImageList"
      />
      <q-card-section>
        2022AW台北時裝週發表，主題「智造無界未來」，<br />
        將數位科技與服裝設計結合，呈現多元的創意風格。<br />
        <br />
      </q-card-section>
    </q-card>
    <q-card class="my-card col-12 col-md-6">
      <q-card-section>
        <div class="text-h5 q-mb-xs">
          2021臺北時裝週AW21
        </div>
      </q-card-section>
      <ExhibitionImage class="my-exhibition-image" :imageList="aw21ImageList" />
      <q-card-section>
        實踐大學服飾設計與經營學系參加2021台北時裝週發表，<br />
        是四校六科系作品中唯一獲得評審加選推薦的！<br />
        <br />
      </q-card-section>
    </q-card>
    <q-card class="my-card col-12 col-md-6">
      <q-card-section>
        <div class="text-h5 q-mb-xs">
          高雄時尚大賞風行潮時尚
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="gaoxiongImageList"
      />
      <q-card-section>
        感謝多家媒體報導！！！學生自創品牌 <br />
        受邀高雄時尚大賞-風行潮時尚Runway show<br />
        Inmoreis、Jar Ping Liao、Psycho cycle<br />
      </q-card-section>
    </q-card>
    <q-card class="my-card col-12 col-md-6">
      <q-card-section>
        <div class="text-h5 q-mb-xs">
          學生期末作品Runway展演
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="runwayImageList"
      />
      <q-card-section>
        服飾設計與經營學系期末runway show，<br />
        以國際時裝周為概念，學習如同品牌設計師，<br />
        規劃每年兩次春夏、秋冬系列作品發表。<br />
      </q-card-section>
    </q-card>
    <q-card class="my-card col-12 col-md-6">
      <q-card-section>
        <div class="text-h5 q-mb-xs">
          畢業展演
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="graduationImageList"
      />
      <q-card-section><br /><br /><br /></q-card-section>
    </q-card>
    <q-card class="my-card col-12 col-md-6">
      <q-card-section>
        <div class="text-h5 q-mb-xs">
          學生創作
        </div>
      </q-card-section>
      <ExhibitionImage class="my-exhibition-image" :imageList="imageList" />
      <q-card-section><br /><br /><br /></q-card-section>
    </q-card>
  </div>
</template>

<script>
import ExhibitionImage from "@/components/ExhibitionImage.vue";

export default {
  setup() {
    return {
      xindaiImageList: [
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3421_8095943_56047.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3423_1565557_56077.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3422_3797747_56063.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3424_610578_56094.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3425_4581842_56111.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3426_8342304_56133.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3427_9573625_56150.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3428_6383340_56167.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3429_6535587_56186.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3430_9442798_56209.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3431_8480725_56228.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3432_6284641_56242.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3433_3141425_56256.jpg"
        }
      ],
      tianImageList: [
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3194_369786_89335.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3193_3433650_89320.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3195_9595578_89352.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3196_3953316_89366.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3197_4318580_89382.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3198_4692024_89400.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3199_9692175_89414.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3200_1426893_89428.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3201_2071817_89442.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3202_3603378_89458.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3203_9459490_89472.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3204_2813878_89486.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3205_7768060_89500.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3206_6118521_89516.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3207_8782_89535.jpg"
        }
      ],
      shengengImageList: [
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3181_5410163_88034.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3188_7562207_88166.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3182_8106173_88050.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3183_1907474_88080.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3184_7982229_88097.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3185_2838405_88113.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3186_2567114_88132.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3187_6961153_88150.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3189_2503550_88183.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3190_1911393_88198.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3178_335971_87239.jpg"
        }
      ],
      chengyiImageList: [
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3152_26903_84960.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3157_4297751_85248.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3158_4480570_85279.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3153_5416092_84974.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3155_2527798_85005.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3156_8747097_85021.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3258_5469389_54225.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3259_4650838_54246.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3260_1204587_54263.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3261_9339107_54285.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3262_3644512_54303.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3263_2492453_54330.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3264_2979217_54350.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3265_8222330_54377.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3266_6205226_54395.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3267_6135905_54415.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3268_1643821_54435.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3269_4083601_54457.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3270_8127383_54474.jpg"
        }
      ],
      zhinengImageList: [
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3025_8519518_31159.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3009_4774907_30877.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3010_3575722_30890.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3011_6824395_30904.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3012_6551870_30919.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3013_2097273_30942.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3014_7858363_30995.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3015_1819528_31014.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3016_4988436_31030.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3017_3519616_31045.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3018_8616718_31059.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3019_5186062_31075.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3020_9865235_31089.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3021_4690253_31105.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3022_9320711_31118.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3023_7349396_31133.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3024_9667661_31146.jpg"
        }
      ],
      aw21ImageList: [
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2909_7850857_30075.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2910_6678958_30089.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2911_3603261_30104.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2912_2749629_30120.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2913_8793582_30134.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2914_4815795_30148.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2915_1064081_30161.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2916_299937_30174.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2917_4688312_30188.jpg"
        }
      ],
      gaoxiongImageList: [
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2941_4862933_37159.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2949_1337049_37294.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2942_1212109_37175.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2943_6616797_37193.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2944_5129501_37209.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2945_5511700_37225.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2946_1808255_37241.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2947_7163250_37263.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2948_8265853_37279.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2950_1093548_37310.jpg"
        }
      ],
      runwayImageList: [
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2952_8604863_37978.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2951_2971934_37960.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2953_824041_37994.jpg"
        }
      ],
      graduationImageList: [
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2940_5823461_36655.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2939_8294294_36628.jpg"
        }
      ],
      imageList: [
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2417_2093097_93309.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2419_4447715_93405.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2447_547459_96223.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2451_1043386_96425.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2457_7139066_96852.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2463_700371_97281.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2467_6054881_97482.jpg"
        }
      ]
    };
  },
  components: {
    ExhibitionImage
  }
};
</script>
