<template>
  <q-page>
    <div class="activity">
      <!-- <h1>This is an activity page</h1> -->
      <ActivityTabPanel />
    </div>
  </q-page>
</template>

<script>
// @ is an alias to /src
import ActivityTabPanel from "@/components/ActivityTabPanel.vue";

export default {
  setup() {
    return {};
  },
  name: "Activity",
  components: {
    ActivityTabPanel
  }
};
</script>
