<template>
  <div class="text-h4 q-mb-md">
    企業參訪<br />
    <!-- <p class="text-h6">
      提供學生與企業近距離接觸的機會，透過真實的產業觀察，<br />
      學習到不同於書本上的知識和體驗。
    </p> -->
  </div>
  <div class="q-pa-md row items-start q-gutter-none">
    <q-card class="my-card col-12 col-md-6">
      <q-card-section>
        <div class="text-h5 q-mb-xs">
          半山夢工廠 | 樹德收納學旅工場
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="babbuzaImageList"
      />
      <!-- <img src="https://cdn.quasar.dev/img/mountains.jpg" /> -->
    </q-card>
    <q-card class="my-card col-12 col-md-6">
      <q-card-section>
        <div class="text-h5 q-mb-xs">
          西園29服飾創作基地
        </div>
      </q-card-section>
      <ExhibitionImage class="my-exhibition-image" :imageList="imageList" />
      <!-- <img src="https://cdn.quasar.dev/img/mountains.jpg" /> -->
    </q-card>
  </div>
</template>

<script>
import ExhibitionImage from "@/components/ExhibitionImage.vue";

export default {
  setup() {
    return {
      babbuzaImageList: [
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3208_5766263_90385.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3209_900816_90398.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3210_2160381_90412.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3211_1695461_90425.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3212_5649215_90441.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3213_2463958_90454.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3154_5571063_84988.jpg"
        }
      ],
      imageList: [
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2528_779890_89968.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2529_5342356_89984.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2530_7040921_89998.jpg"
        }
      ]
    };
  },
  components: {
    ExhibitionImage
  }
};
</script>
