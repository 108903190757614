<template>
  <div class="text-h4 q-mb-md">
    移地教學<br />
    <!-- <p class="text-h6">
      學習不應該只侷限在教室，而是探索、發現與感動的過程，<br />
      開拓視野，重拾好奇心，結合五感體驗，讓學習更貼近生活。
    </p> -->
  </div>
  <div class="q-pa-md">
    <q-video :ratio="16 / 9" src="https://www.youtube.com/embed/gGPJbY9wbTU" />
  </div>
  <div class="q-pa-md row items-start q-gutter-none">
    <q-card class="my-card col-12 col-md-6">
      <q-card-section>
        <div class="text-h6 q-mb-xs">
          2024暑期日本移地教學<br />
          <br />
          <br />
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="japan24ImageList"
      />
      <!-- <img src="https://cdn.quasar.dev/img/mountains.jpg" /> -->
    </q-card>
    <q-card class="my-card col-12 col-md-6">
      <q-card-section>
        <div class="text-h6 q-mb-xs">
          2023東京移地教學<br />
          實踐大學服飾與經營學系X亞洲No.1 日本文化服裝學院<br />
          2023冬季移地教學：專業課程+企業參訪+藝術體驗
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="dongjing23ImageList"
      />
      <!-- <img src="https://cdn.quasar.dev/img/mountains.jpg" /> -->
    </q-card>
    <q-card class="my-card col-12 col-md-6">
      <q-card-section>
        <div class="text-h6 q-mb-xs">
          2022臺灣文博會X台灣設計展在高雄<br />
          設計中島| 2022 台灣設計展Taiwan Design Expo<br />
          【台灣設計設計台灣】
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="zhongdaoImageList"
      />
      <!-- <img src="https://cdn.quasar.dev/img/mountains.jpg" /> -->
    </q-card>
    <q-card class="my-card col-12 col-md-6">
      <q-card-section>
        <div class="text-h6 q-mb-xs">
          奇美博物館<br />
          <br />
          <br />
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="chimeiImageList"
      />
      <!-- <img src="https://cdn.quasar.dev/img/mountains.jpg" /> -->
    </q-card>
    <q-card class="my-card col-12 col-md-6">
      <q-card-section>
        <div class="text-h6 q-mb-xs">
          跨校合作教學<br />
          與國立雲林科技大學創意生活設計學系合作<br />
          共同設計課程+實作教學+整合跨領域人才
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="yunkeImageList"
      />
      <!-- <img src="https://cdn.quasar.dev/img/mountains.jpg" /> -->
    </q-card>
    <q-card class="my-card col-12 col-md-6">
      <q-card-section>
        <div class="text-h6 q-mb-xs">
          英國V&A博物館世界巡迴展<br />
          《蒂姆．沃克：美妙事物》<br />
          （Tim Walker: Wonderful Things）
        </div>
      </q-card-section>
      <ExhibitionImage class="my-exhibition-image" :imageList="imageList" />
      <!-- <img src="https://cdn.quasar.dev/img/mountains.jpg" /> -->
    </q-card>
  </div>
</template>

<script>
import ExhibitionImage from "@/components/ExhibitionImage.vue";
import { useQuasar } from "quasar";
import { computed } from "vue";

export default {
  setup() {
    const $q = useQuasar();

    return {
      japan24ImageList: [
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3677_5169595_94695.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3676_6460480_94678.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3678_8175105_94710.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3679_9355818_94726.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3680_3058280_94740.jpg"
        }
      ],
      dongjing23ImageList: [
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3160_632814_86308.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3159_2429199_86292.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3161_7443905_86323.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3162_6227808_86337.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3163_8844128_86352.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3164_4555679_86367.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3165_3295018_86386.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3166_6025569_86405.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3167_6160242_86422.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3168_932023_86439.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3169_376343_86459.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3171_4405221_86495.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3271_4109238_56540.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3272_6576001_56567.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3273_5264655_56585.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3274_1480220_56606.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3275_125867_56642.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3276_4625675_56659.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3277_4735763_56680.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3278_253466_56700.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3279_9851983_56720.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3280_7774030_56738.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3281_6810911_56757.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3282_8951953_56779.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3283_1195311_56797.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3284_1702567_56815.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3285_5246287_56833.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3286_3160075_56851.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3287_4060079_56872.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3288_931159_56890.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3289_5846154_56907.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3290_872095_56926.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3291_6186546_56944.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3292_1937862_56962.jpg"
        }
      ],
      zhongdaoImageList: [
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3236_1815224_91735.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3236_1815224_91735.jpg"
        }
      ],
      chimeiImageList: [
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3235_9017159_91718.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3235_9017159_91718.jpg"
        }
      ],
      yunkeImageList: [
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3222_9501744_91342.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3223_1695839_91391.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3224_3306312_91403.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3225_8902269_91417.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3226_5466230_91432.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3227_1498863_91447.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3228_4516677_91462.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3229_6524337_91478.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3230_760654_91495.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3231_6010100_91511.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3232_5447789_91525.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3233_5462089_91540.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3234_283238_91554.jpg"
        }
      ],
      imageList: [
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2907_729387_29249.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2908_4914153_29272.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2905_5522312_29208.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2906_1470300_29229.jpg"
        }
      ],
      layout: computed(() => {
        return $q.screen.lt.sm
          ? "dense"
          : $q.screen.lt.md
          ? "comfortable"
          : "loose";
      })
    };
  },
  components: {
    ExhibitionImage
  }
};
</script>
